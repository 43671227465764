import { SVGInjector } from '@tanem/svg-injector';
import DOMPurify from 'dompurify';

export default {
  init() {
    // JavaScript to be fired on all pages
    const ajax_url = window.sage.ajax_url || false;
    const btns = document.querySelectorAll('.hamburger');
    const nav = document.querySelector('.sidebar-menu');

    btns.forEach(function(btn) {
      btn.onclick = () => {
        btn.classList.toggle('is-active');
        nav.classList.toggle('is-open');
        document.body.classList.toggle('is-sidebar-open');
        document.body.classList.add('is-sidebar-animating');
      }
    });

    nav.addEventListener('transitionend', () => {
      document.body.classList.remove('is-sidebar-animating');
    });



    const el = document.querySelector('.site-overlay');
    if(el) {
      el.onclick = () => {
        btns.forEach(function(btn) {
          btn.classList.toggle('is-active');
        });
      }
    }

    SVGInjector(document.querySelectorAll('img.injectable'));

    document
      .querySelectorAll('.js-load-more')
      .forEach(btn => {
        btn.onclick = function(e) {
          e.preventDefault();
          btn.querySelector('.spinner-border').classList.remove('d-none');
          const queryVars   = btn.getAttribute('data-query');
          const template    = btn.getAttribute('data-template');
          const container   = btn.getAttribute('data-container');
          let currentPage   = parseInt(btn.getAttribute('data-current-page'));
          const maxPages    = parseInt(btn.getAttribute('data-max-pages'));
          const data = new FormData();
          data.append('action', 'loadmore');
          data.append('nonce', window.sage.nonce);
          data.append('template', template);
          data.append('query', queryVars);
          data.append('page', currentPage.toString());
          const params = new URLSearchParams(Array.from(data)).toString();
          const xhr = new XMLHttpRequest();
          xhr.open('POST', ajax_url, true);
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          xhr.onreadystatechange = function() {
            if(xhr.readyState === XMLHttpRequest.DONE) {
              btn.querySelector('.spinner-border').classList.add('d-none');
              if(xhr.status === 200) {
                const response = JSON.parse(xhr.response);
                const el = document.querySelector(container);
                el.insertAdjacentHTML('beforeend', DOMPurify.sanitize(response.data, {USE_PROFILES: {html: true}}))
                btn.setAttribute('data-current-page', ++currentPage);
                if(currentPage >= maxPages) {
                  btn.classList.add('d-none');
                }
              } else {
                console.log('ERROR');
              }
            }
          }
          xhr.send(params);
        }
      });

    const formsWithAnimatedLabels = document.querySelectorAll(
      '.form-with-animated-labels'
    );
    const focusedClass = 'focused';
    
    for (const form of formsWithAnimatedLabels) {
      const formControls = form.querySelectorAll(
          '[type="text"], [type="email"], textarea'
      );
      for (const formControl of formControls) {
          formControl.addEventListener('focus', function () {
              this.parentElement.nextElementSibling.classList.add(focusedClass);
          });
    
          formControl.addEventListener('blur', function () {
              if (!this.value) {
                  this.parentElement.nextElementSibling.classList.remove(
                      focusedClass
                  );
              }
          });
      }
    
    
      form.parentElement.addEventListener('wpcf7mailsent', function () {
          const labels = document.querySelectorAll('.focused');
          for (const label of labels) {
              label.classList.remove(focusedClass);
          }
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
